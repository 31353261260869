import { graphql } from "gatsby";
import { block } from "bem-cn";

import Layout from "layouts/";
import Heading from "components/Heading";
import MediumContainer from "components/Container/_medium";

import {
  insideLink,
  outsideLink,
  ul,
  li,
  renderAst,
  img,
} from "components/markDownComponents";

const renderComToolkit = {
  ul: ul,
  li: li,
  "inside-link": insideLink,
  a: outsideLink,
  img: img,
};

const renderCom = renderAst(renderComToolkit);

import "./style.scss";

const cn = block("legals-template");

const LegalTemplate = ({ data }) => {
  const { htmlAst } = data.markdownRemark;
  const { title, seo } = data.markdownRemark.frontmatter;

  return (
    <Layout seo={seo}>
      <MediumContainer className={cn("wrap")}>
        <Heading color="general" weight="bold" className={cn("title")}>
          {title}
        </Heading>
        {renderCom(htmlAst)}
      </MediumContainer>
    </Layout>
  );
};

export default LegalTemplate;

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      htmlAst
      frontmatter {
        title
        seo {
          description
          image
          link
          noForRobots
          title
        }
      }
    }
  }
`;
